import {Cache} from 'aws-amplify';
import {
    notifyError,
    notifySuccess
} from "../../../components/ServiceResponseNotifications";
import {Account} from "../types.ts";
import {getVerbosityLevel} from "../../sign-in-profiles/services/SignInProfilesService.ts";
import {VerbosityLevel} from "../../sign-in-profiles/types.ts";

const key = 'pac-accounts';

export const getWithEmail = async (email: string) => {

    const verbosity = await getVerbosityLevel()

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    }) as Account[];


    // search using emailAddress property
    const found = accounts.find((a: Account) => a.emailAddress?.toLowerCase() === email.toLowerCase());

    if (typeof found !== 'undefined') {
        verbosity === VerbosityLevel.HIGH && notifySuccess('Account found - ' + email);
        return found;
    } else {
        // search using the userId property of the first identity
        const matchingAccount = accounts.find((a: Account) => typeof a.identities !== 'undefined' && a.identities.length > 0 && a.identities[0].userId.toLowerCase() === email.toLowerCase());
        if (typeof matchingAccount !== 'undefined') {
            verbosity === VerbosityLevel.HIGH && notifySuccess('Account found - ' + email);
            return matchingAccount;
        } else {
            notifyError(`Account not found. ${email} does not match any accounts`);
            return accounts.length > 0 ? accounts[0] : null
        }
    }


};

/*
    @param {object} idTokenPayload
*/
export const getWithIdTokenPayload = async (idTokenPayload: {
    email: string,
    identities: { userId: string }[] | undefined
}) => {

    const verbosity = await getVerbosityLevel()

    let email = idTokenPayload.email;

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });


    // search using emailAddress property
    const found = accounts.find((a: { emailAddress: string }) => a.emailAddress.toLowerCase() === email.toLowerCase());

    if (typeof found !== 'undefined') {
        verbosity === VerbosityLevel.HIGH && notifySuccess('Account found with email - ' + email);
        return found;
    } else {
        // search using the userId property of the first identity
        const matchingAccount = accounts.find((a: { emailAddress: string }) => {
            return typeof idTokenPayload.identities !== 'undefined' &&
                idTokenPayload.identities.length > 0 &&
                a.emailAddress.toLowerCase() === idTokenPayload.identities[0].userId.toLowerCase()
        });

        if (typeof matchingAccount !== 'undefined') {
            if (typeof idTokenPayload.identities !== 'undefined' && idTokenPayload.identities.length > 0) {
                verbosity === VerbosityLevel.HIGH && notifySuccess('Account found - ' + idTokenPayload.identities[0].userId);
            }
            return matchingAccount;
        } else {
            verbosity === VerbosityLevel.HIGH && notifyError(`Account not found. ${email} does not match any accounts`);
            return accounts.length > 0 ? accounts[0] : null
        }
    }


};

export const fetchRecordsWithAuthorizingFlag = async () => {

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    return accounts.filter((a: { isAuthorizing: string }) => a.isAuthorizing === 'yes');

};

export const fetchRecords = async (emailAddress: string) => {
    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });
    if (emailAddress) {
        return accounts.filter((a: { emailAddress: string }) => a.emailAddress === emailAddress) as Account[];
    }
    return accounts as Account[];
};


export const fetch = async (id: string) => {
    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    return accounts.find((a: { id: string }) => a.id === id);

};

export const add = async (payload: Account) => {

    const verbosity = await getVerbosityLevel()

    payload.createdAt = new Date().toISOString();

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    accounts.push(payload);
    Cache.setItem(key, accounts);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Account added');
    return payload;

};

export const update = async (recordId: string, record: Account) => {
    const verbosity = await getVerbosityLevel()

    if (record.isAuthorizing) {
        Cache.setItem('pac-authorizing', record.isAuthorizing);
    }

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const index = accounts.findIndex((a: { id: string }) => a.id === recordId);
    accounts[index] = record;

    const expiration = new Date();
    // expire in one month
    expiration.setMonth(expiration.getMonth() + 1);

    Cache.setItem(key, accounts, {expires: expiration.getTime()});
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Account updated');
    return record;
};

export const deleteRecord = async (recordId: string) => {

    const verbosity = await getVerbosityLevel()

    const accounts = Cache.getItem(key, {
        callback: () => {
            return [];
        },
    });

    const index = accounts.findIndex((a: { id: string }) => a.id === recordId);
    accounts.splice(index, 1);
    Cache.setItem(key, accounts);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('Account deleted');
    return recordId;
};

export const deleteAllRecords = async () => {
    const verbosity = await getVerbosityLevel()


    Cache.removeItem(key);
    verbosity === VerbosityLevel.MEDIUM && notifySuccess('All accounts deleted');
    return true;
};
